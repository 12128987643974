@import 'bootstrap/dist/css/bootstrap.min.css';
@tailwind base;
@tailwind components;
@tailwind utilities;


.banner-bg {
  background-image: url("../src/assets/images/background1.png");
  background-size: auto;
}

.auth-bg {
  background-image: url("../src/assets/images/tech4.jpg");
  background-size: cover;
}

.cart-bg {
  background-image: url("../src/assets/images/cart-bg.jpg");
  background-size: auto;
}

.carousel-icon {
  color: #647C90;
  opacity: 0.5;
}